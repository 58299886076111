import { z } from 'zod';

export const formSchema = z.object({
  name: z.string().min(3, { message: '3文字以上で入力してください' }).max(20, { message: '20文字以内で入力してください' }),
  email: z.string().min(1, { message: '必須項目です' }).email({ message: 'メールアドレスを入力してください' }),
  content: z.string().min(5, { message: '5文字以上で入力してください' }).max(400, { message: '400文字以内で入力してください' })
})

export const loginFormSchema = z.object({
  email: z.string().min(1, { message: '必須項目です' }),
  password: z.string().min(1, { message: '必須項目です' })
})