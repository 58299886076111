'use client'
import { RecoilRoot } from "recoil";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Modal from "./components/Modal";


export default function FrontLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <>
      <RecoilRoot>
        <Header />
        <main>{children}</main>
        <Footer />
      </RecoilRoot>
    </>
  );
}
