export const webSite = {
  title: 'WEBsite',
  desc: 'ご予算やご要望に合わせて、<br />WEBサイトを制作いたします。<br />下記に一例をご紹介します。',
  items: [
    {
      title: 'サイトの表示が遅い',
      body: 'サイトの表示が遅いとGoogleの評価も下がってしまいます。<br />Googleの評価が下がればSEOにも不利になります。このようなご要望にはNextJSやSVELTEなどのJavaScriptフレームワークを使用し、ページ遷移などを高速に動くようにいたします。'
    },
    {
      title: 'ECサイトが欲しい',
      body: 'Shopifyを使用すれば、ECサイトをすぐに公開することが可能です。また公開後のカスタムなども可能です。<br />すぐに始めたい方にも、オリジナル性のある凝ったECサイトを運営したい方にも対応いたします。'
    },
    {
      title: '低予算かつ即納してほしい',
      body: '現在はWEBサイトを制作するのに、コードを記述する必要のないノーコードツールを選択されるのもおすすめです。カスタマイズ性があるものや、安価なものなど、さまざまなノーコードツールがあります。ご要望をお聞かせいただき、ご提案させていただきます。'
    },
    {
      title: 'やっぱりWordPressがいい',
      body: '今でも高い人気のあるCMSにWordPressがあります。実績があるWordPressでWEBサイトを制作されたい方にももちろんご対応いたします。WordPressはインターネットなどにさまざまな情報がありますので、お客様自身での公開後の運用も安心です。'
    },
  ]
}

export const webApp = {
  title: 'WEBapp',
  desc: '<br /><br />',
  items: [
    {
      title: '',
      body: ''
    },
    {
      title: '',
      body: ''
    },
    {
      title: '',
      body: ''
    },
    {
      title: '',
      body: ''
    },
  ]
}

export const ecSite = {
  title: 'ECsite',
  desc: 'ECサイトを始めたい方に<br />各種ツールなどを使用したECサイトを<br />構築いたいます。',
  items: [
    {
      title: 'Shopify',
      body: 'Shopifyを使用すれば、すぐにでもECサイトを運用することが可能です。独自のデザインでオリジナル性のあるECサイトも制作可能です。また既存のサイトのカスタムもご相談ください。'
    },
    {
      title: 'WordPress',
      body: '構築に少しお時間か必要ですが、WordPressサイトにもEC機能を構築することが可能です。新規で作成する場合でも、既存のWordPressサイトにEC機能の追加なども行えます。'
    },
    {
      title: 'その他のツール',
      body: 'その他に簡単にECサイトを構築する方法もございます。カスタマイズ性は少し落ちますが、導入までが早く、お客様ご自身で簡単に管理が可能なので、新商品の販売などもスムーズに行えます。'
    },
    {
      title: 'オリジナルでの制作',
      body: 'フルスクラッチによるECサイトの構築も可能です。ゼロからの作り込みになりますので、お客様のご要望などを自由に取り込むことが可能です。ただしコストと納期に余裕が必要です。'
    },
  ]
}

export const design = {
  title: 'Design',
  desc: 'デザインのみの制作も可能です。<br />使用ツールは「Figma」もしくは「Adobe XD」です。<br />イラストレーターやフォトショップでの納品はしておりません。',
  items: [
    {
      title: '',
      body: ''
    },
    {
      title: '',
      body: ''
    },
    {
      title: '',
      body: ''
    },
    {
      title: '',
      body: ''
    },
  ]
}