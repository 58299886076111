"use client";
import { isModalState } from "@/states/modalState";
import { useRecoilValue, useSetRecoilState } from "recoil";

import WorksItem from "./WorksItem";

const WorksList = () => {
  const isModal = useRecoilValue(isModalState);
  const setIsModal = useSetRecoilState(isModalState);

  return (
    <ul className="works-list mt-14 md:flex flex-wrap md:gap-y-8 md:justify-between">
      <WorksItem title="WEBsite">
        <p className="text-left tracking-[0.2em] leading-[1.7]">
          コーポレートサイト・LP制作などのコーディングを必要とするサイトから、
          納期やご予算に合わせてノーコードツールなど、さまざまな方法をご提案いたします。
        </p>
      </WorksItem>
      <WorksItem title="WEBapp">
        <p className="text-left tracking-[0.2em] leading-[1.7]">
          NodeJS、Python、PHPなどのプログラミング言語を使用して、
          WEBアプリを制作いたします。
        </p>
      </WorksItem>
      <WorksItem title="ECsite">
        <p className="text-left tracking-[0.2em] leading-[1.7]">
          すぐにECサイトを始めたい方にShopifyを使用したECサイトを構築いたします。
          また既存のShopifyサイトのカスタマイズなどもご相談ください。
        </p>
      </WorksItem>
      <WorksItem title="Design">
        <p className="text-left tracking-[0.2em] leading-[1.7]">
          WEBサイトのデザインのみの制作も行なっております。
          ヒアリングを基にワイヤーフレームの作成から、デザインまでご相談ください。
        </p>
      </WorksItem>
    </ul>
  );
};

export default WorksList;
