import Link from "next/link";
import CloseBtn from "./CloseBtn";
import { useSetRecoilState } from 'recoil';
import { isMenuState } from "@/states/menuState";

const SideMenu = () => {
  const setIsMenu = useSetRecoilState(isMenuState)

  const handleClick = () => {
    setIsMenu((flag) => !flag)
  }

  return (
    <div className="side-menu">
      <nav className="side-menu-nav">
        <ul className="side-menu-list font-josefinSans text-lg">
          <li className="side-menu-item">
            <Link href="/" onClick={handleClick}>Top</Link>
            <ul className="sub-menu-list mt-6">
              <li className="sub-menu-item">
                <Link href="/#about" onClick={handleClick}>About</Link>
              </li>
              <li className="sub-menu-item">
                <Link href="/#works" onClick={handleClick}>Works</Link>
              </li>
              <li className="sub-menu-item">
                <Link href="/#skill" onClick={handleClick}>Skill</Link>
              </li>
              <li className="sub-menu-item">
                <Link href="/#contact" onClick={handleClick}>Contact</Link>
              </li>
            </ul>
          </li>
          <li className="side-menu-item mt-20">
            <Link href="/production">Production</Link>
          </li>
        </ul>
        <CloseBtn action={handleClick}/>
      </nav>
    </div>
  );
};

export default SideMenu;
