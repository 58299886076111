const Footer = () => {
  return (
    <footer className="footer font-josefinSans pt-9 pb-7 mt-40 md:relative">
      <nav className="footer__nav container">
        <ul className="footer__nav-list flex justify-between md:max-w-[29.5%] md:mx-auto md:justify-between">
          <li className="footer__nav-item">
            <a href="#about">About</a>
          </li>
          <li className="footer__nav-item">
            <a href="#works">Works</a>
          </li>
          <li className="footer__nav-item">
            <a href="#skill">Skill</a>
          </li>
          <li className="footer__nav-item">
            <a href="#contact">Contact</a>
          </li>
        </ul>
      </nav>
      <div className="text-center mt-16 md:mt-0 md:absolute md:right-10 md:top-1/2">
        <a href="#header">Page Top</a>
      </div>
    </footer>
  );
};

export default Footer;
