import { isMenuState } from "@/states/menuState";
import Image from "next/image"
import { useRecoilValue, useSetRecoilState } from 'recoil';

const MenuIcon = () => {
  const isMenu = useRecoilValue(isMenuState);
  const toggleMenu = useSetRecoilState(isMenuState);

  const handleClick = () => {
    toggleMenu(!isMenu);
  }
  return (
    <button className="menu-open" type="button" title="メニュー開閉ボタン" onClick={handleClick}>
      <Image src="/icon/menu-icon.svg" alt="" width={19} height={12}/>
    </button>
  );
}

export default MenuIcon;