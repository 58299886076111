import Link from "next/link";
import { Link as Scroll } from 'react-scroll';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import MenuIcon from "./MenuIcon";
import { useRecoilValue } from "recoil";
import { isMenuState } from "@/states/menuState";
import SideMenu from "./SideMenu";

const Header = () => {
  const isMenu = useRecoilValue(isMenuState);
  
  return (
    <>
      <header
        id="header"
        className="header font-josefinSans md:bg-[#FAFAFA] md:py-8"
      >
        <nav className="nav container md-full-width pt-3">
          <ul className="nav-list flex justify-end items-center">
            <li className="hidden md:block">
              <Link href="/#about">About</Link>
            </li>
            <li className="hidden md:block md:ml-10">
              <Link href="/#works">Works</Link>
            </li>
            <li className="hidden md:block md:ml-10">
              <Link href="/production">Production</Link>
            </li>
            <li className="hidden md:block md:ml-10">
              <Link href="/#skill">Skill</Link>
            </li>
            <li className="hidden md:block md:ml-10">
              <Link href="/#contact">Contact</Link>
            </li>
            <li className="ml-auto md:ml-10">
              <MenuIcon />
            </li>
          </ul>
        </nav>
      </header>
      {isMenu && <SideMenu />}
    </>
  );
};

export default Header;
