import { atom, selector } from "recoil";

import { webSite, webApp, ecSite, design } from "@/template/modalTemplate";

export const isModalState = atom({
  key: "isModalState",
  default: "",
});

export const isModalSelector = selector({
  key: "selectModalType",
  get: ({ get }) => {
    const title = get(isModalState);
    switch (title) {
      case "WEBsite":
        return {
          title: webSite.title,
          desc: webSite.desc,
          items: [...webSite.items],
        };
        break;
      case "WEBapp":
        return {
          title: webApp.title,
          desc: webApp.desc,
          items: [...webApp.items],
        };
        break;
      case "ECsite":
        return {
          title: ecSite.title,
          desc: ecSite.desc,
          items: [...ecSite.items],
        };
        break;
      case "Design":
        return {
          title: design.title,
          desc: design.desc,
          items: [...design.items],
        };
        break;
      default:
        return {};
    }
  },
});
