"use client";
import CloseBtn from "./CloseBtn";
import ModalDetail from "./ModalDetail";
import { RecoilValueReadOnly, useRecoilValue, useSetRecoilState } from "recoil";
import { isModalSelector, isModalState } from "@/states/modalState";

import { webSite, webApp, ecSite, design } from '@/template/modalTemplate';
import { ModalType } from "@/type/modal";

const Modal = () => {
  const isModal = useRecoilValue(isModalState);
  const {title, desc, items} = useRecoilValue(isModalSelector);
  const setIsModal = useSetRecoilState(isModalState);
  
  
  const handleClick = () => {
    setIsModal((str) => '')
  }

  return (
    isModal && (
      <div className="modal">
        <div className="modal-body">
          <div className="modal-header">
            <h3 className="modal-ttl font-josefinSans text-lg">{ title }</h3>
            <p className="modal-desc text-xs mt-11" dangerouslySetInnerHTML={{ __html: desc! }} ></p>
            <div className="modal-list mt-16 md:mt-20 md:flex md:flex-wrap md:justify-between gap-y-[1.875rem]">
              { items && items.map((item) => (
              <ModalDetail key={item.title}
                title={item.title}
                body={item.body}
              />
              )) }
            </div>
            <CloseBtn action={handleClick} />
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
