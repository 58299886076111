'use client'
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isModalState } from '@/states/modalState';


const WorksItem = ({ title, children }: { title: string, children: React.ReactNode }) => {
  const isModal = useRecoilValue(isModalState);
  const setIsModal = useSetRecoilState(isModalState);

  const handleClick = () => {
    setIsModal(title);
  }
  return (
    <li className="works-item border border-[#D9D9D9] pt-5 pb-10 md:flex md:items-center md:py-10" onClick={handleClick} role="button">
      <h3 className="works-item-ttl font-josefinSans py-1 md:py-5 md:pl-6 md:pr-14 md:text-lg">{ title }</h3>
      <div className="works-item-desc px-[15px] text-xs mt-5 md:mt-0">
        { children }
      </div>
    </li>
  );
};

export default WorksItem;
