import { isModalState } from '@/states/modalState';
import { useSetRecoilState } from 'recoil';
const ModalDetail = ({title, body}: {title: string, body: string}) => {

  const setIsModal = useSetRecoilState(isModalState);
  const handleClick = () => {
    setIsModal((str) => title)
  }

  return (
    <article className="modal-item text-[0.625rem] md:text-xs text-left border border-[#D9D9D9] p-4 md:p-5">
      <h4 className="modal-item-ttl" onClick={handleClick}>{ title }</h4>
      <p dangerouslySetInnerHTML={{ __html: body }} className="mt-3 leading-[1.7]"></p>
    </article>
  );
};

export default ModalDetail;
