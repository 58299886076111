'use client'

import Link from "next/link";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { useForm, SubmitHandler, FieldErrors } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { formSchema } from "@/lib/validationSchema";


type FormInputs = {
  name: string;
  email: string;
  content: string;
}

const url = 'https://www.ninja-designworks.net';
// const url = 'http://localhost:3000';
const endpoint = '/api/send';


const Form = () => {
  const router = useRouter();

  const { register, handleSubmit, formState: { errors } } = useForm<FormInputs>({ mode: 'onChange', resolver: zodResolver(formSchema)});
  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const { name, email, content } = data;
    try {
      const req = await fetch(url + endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({name, email, content}),
      })
      if(req.ok) {
        router.push('/thanks')
      }
    } catch (err) {
      console.log(err);
    }
  }

  const onerror = (err: FieldErrors<{ name: string; email: string; content: string; }>) => console.log(err);

  const [agree, setAgree] = useState(false);
  
  return (
    <form action="" method="POST" className="mt-20 text-left md:max-w-[400px] md:mx-auto" onSubmit={handleSubmit(onSubmit, onerror)}>
      <div className="group">
        <input id="name" type="text" className="input" {...register('name')} />
        <span className="highlight"></span>
        <span className="bar"></span>
        <label className="label font-josefinSans" htmlFor="name">Name:</label>
        {errors?.name && <span className="text-red-500 text-xs">{errors.name?.message}</span>}
      </div>
      <div className="group mt-14">
        <input id="email" type="email" className="input" {...register('email')} />
        <span className="highlight"></span>
        <span className="bar"></span>
        <label className="label font-josefinSans" htmlFor="email">Email:</label>
        {errors?.email && <span className="text-red-500 text-xs">{errors.email?.message}</span>}
      </div>
      <div className="mt-14">
        <label htmlFor="content" className="block font-josefinSans">
          Content:
        </label>
        {errors?.content && <span className="text-red-500 text-xs">{errors.content?.message}</span>}
        <textarea
          id="content"
          className="block w-full mt-4 h-[215px] p-3 text-xs"
          required
          {...register('content')}
        ></textarea>
      </div>
      <div className="mt-14 flex items-center">
        <input type="checkbox" name="agree" id="agree" onChange={(e) => setAgree(!agree)}/>
        <label htmlFor="agree" className="ml-2">
          <Link href="/privacy-policy" className="underline">
            プライバシーポリシーに同意する
          </Link>
        </label>
      </div>
      <input
        type="submit"
        value="Submit"
        className="font-josefinSans text-center w-full mt-16 py-3"
        disabled={!agree}
        />
    </form>
  );
};

export default Form;
