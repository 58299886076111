import { MouseEventHandler } from "react";

const CloseBtn = ({ action = undefined }: {action: MouseEventHandler<HTMLButtonElement> | undefined}) => {
  return (
    <button
      className="modal-close text-[0.625rem] font-josefinSans"
      type="button"
      onClick={action}
    >
      Close
    </button>
  );
};

export default CloseBtn;
